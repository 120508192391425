import { TipBase, TipCalculationBase } from '.';
export interface SiteConfiguration {
  StoreName: string;
  ExternalStoreName: string | null;
  AlohaLoyalty: boolean;
  OrderAnother: boolean;
  OpenTableEnabled: boolean;
  PageServer: boolean;
  PayPalEnabled: boolean;
  SignatureThreshold: number;
  MaxPaymentThreshold: number;
  TipOne: number;
  TipTwo: number;
  TipThree: number;
  CustomerVoice: boolean;
  StoredValue: boolean;
  CurrencySign: string;
  PayPalFindRedirect: boolean;
  PlaceType: PlaceType;
  EDCCvvEnabled: boolean;
  EDCAVSEnabled: boolean;
  IsUseNCRSecurePaymentForCC: boolean;
  IsUseTipConfiguration: boolean;
  TipBase: TipBase;
  TipCalculationBase: TipCalculationBase;
  CompanyId: number | null;
  CPStoreNumber: number | null;
  StoredValueEnabled: boolean;
  CompanyCode: string;
  AsvPrefixEnabled: boolean;
  LoyaltyCompanyId: number | null;
  PaymentMethod: PaymentMethod;
  FPStoreId: string | null;
  FPTerminalId: string | null;
  FPApiEndpoint: string | null;
  FPEnable3DS: boolean;
  FPEnableGooglePay: boolean;
  FPEnableApplePay: boolean;
  FPGatewayMerchantId: string | null;
  FPGooglePayMerchantId: string | null;
  FPGooglePayEnv: string | null;
  AppInsightsInstrumentation: string | null;
  AppInsightsRoleName: string | null;
  AppInsightsRoleInstance: string | null;
}

export enum PlaceType {
  Error = -1,
  TS = 0,
  QS = 1,
  CS = 2,
}

export enum PaymentMethod {
  EDC = 1, // Electornic Draft Capture
  CP = 2, // Connected Payments
  FP = 3, // Freedom Pay
}

export enum FPInitType {
  Standard = 1,
  ThreeDS = 2,
  ApplePay = 3,
  GooglePay = 4,
}

export enum FPPaymentType {
  Card = 'Card',
  ApplePay = 'ApplePay',
  GooglePay = 'GooglePay',
}

export enum FPPageType {
  PAYMENT_METHOD_SELECTION = 0,
  BILLING_INFO = 1,
  CREDIT_CARD_INFO_WITH_3DS = 2,
  CREDIT_CARD_INFO_WITHOUT_3DS = 3,
  LOADING = 4,
}
