import { useAppDispatch, useAppSelector } from 'app/hooks';
import { CheckCodeHeader } from 'components/CheckCodeHeader';
import { ContentContainer } from 'components/ContentContainer';
import { MpHeader } from 'components/Header';
import { PartiallyPaid } from 'components/PartiallyPaid';
import { PaymentSectionLoading } from 'components/PaymentSectionLoading';
import currency from 'currency.js';
import { AddLoyaltySection } from 'features/AddLoyaltySection';
import { selectLoyaltyState } from 'features/AddLoyaltySection/LoyaltySlice';
import { CheckSummary } from 'features/CheckSummary';
import {
  loadCheckInfo,
  loadPaymentInfo,
  setCheckInfo,
  setPaymentInfo,
  setRefreshIntervalId,
} from 'features/Common/checkInfoSlice';
import { getAppliedPaymentInfo, getCheckInfo } from 'features/Common/commonApi';
import { useCheckData } from 'features/Common/useCheckData';
import { setCurrentPage } from 'features/Common/userPageSlice';
import { useSiteConfig } from 'features/Common/useSiteConfig';
import {
  addFreedomPayScript,
  removeFreedomPayScript,
} from 'features/Common/utils';
import { CreditCardInputSection } from 'features/CreditCardInputSection';
import { clearCreditCardState } from 'features/CreditCardInputSection/CreditCardInputSlice';
import { useCreditCardInput } from 'features/CreditCardInputSection/useCreditCardInput';
import { GiftCardSection } from 'features/GiftCardSection';
import {
  clearGiftCardInputs,
  selectGiftCardState,
} from 'features/GiftCardSection/GiftCardSlice';
import {
  closeModal,
  ModalOptions,
  openModal,
  setModalOptions,
} from 'features/Modal/modalSlice';
import { SecurePaymentProcessing } from 'features/SecurePaymentProcessing/SecurePaymentProcessing';
import { TipSection } from 'features/TipSection';
import {
  navigateToCheckPage,
  navigateToPaymentConfirmationPage,
} from 'pages/navigation';
import { Drawer } from 'pages/Payment/Drawer';
import {
  closeDrawer,
  selectDrawerState,
} from 'pages/Payment/Drawer/drawerSlice';
import {
  resetFPAdditionalInput,
  selectFPAdditionalInput,
  setFpIframeHeight,
  setIsFpButtonDisabled,
  setIsFpLoading,
} from 'pages/Payment/FreedomPay/FPAdditionalInputSlice';
import { FreedomPayIframe } from 'pages/Payment/FreedomPay/FreedomPayIframe';
import {
  completeNCRSecurePaymentsTransaction,
  sendPaymentKey,
} from 'pages/Payment/NCRSecurePayment/NCRSecurePaymentApi';
import { NCRSecurePaymentIframe } from 'pages/Payment/NCRSecurePayment/NCRSecurePaymentIframe';
import { PayButton } from 'pages/Payment/PayButton';
import {
  selectPaymentState,
  setPaidAmount,
  setPaymentId,
  updatePaymentTotal,
} from 'pages/Payment/paymentSlice';
import {
  is3DSAuthFailed,
  maskGoogleApplePayCardNumber,
  splitExpirationDate,
} from 'pages/Payment/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { MpResult } from 'types';
import {
  checkDataPollingIntervalMs,
  codeCookie,
  DrawerContentOption,
  eTagCookie,
  firstLoadCookie,
  PageName,
} from 'types/constants';
import {
  FreedomPayKeyRequest,
  NCRSecurePaymentMakeTransactionRequest,
  NCRSecurePaymentStartSessionRequest,
} from 'types/DTOs';
import {
  FreedomPaymentKeyData,
  FreedomPayMessageType,
  GoogleApplePaymentKeyData,
} from 'types/DTOs/NCRSecurePayment/FreedomPaymentKeyData';
import { MakeSecurePaymentRequest } from 'types/DTOs/NCRSecurePayment/MakeSecurePaymentRequest';
import {
  FPInitType,
  FPPaymentType,
  PaymentMethod,
} from 'types/DTOs/SiteConfiguration';
import { generalErrorMessages, paymentErrorMessages } from 'types/strings';
import { traceAIEvent } from 'utils';

export const PaymentPage = () => {
  const history = useHistory();
  const cookies = new Cookies();

  //#region Redux Store
  const dispatch = useAppDispatch();
  const {
    siteConfig,
    siteConfigStatus,
    isApplePayEnabled,
    isGooglePayEnabled,
  } = useSiteConfig();
  const { paymentTotal } = useAppSelector(selectPaymentState);
  const { open } = useAppSelector(selectDrawerState);
  const { isCreditCardInputsValid } = useCreditCardInput();
  const { isLoyaltyASVCard } = useAppSelector(selectGiftCardState);
  const { loyaltyIsProcessing } = useAppSelector(selectLoyaltyState);
  const giftCard = useAppSelector(selectGiftCardState);
  const payment = useAppSelector(selectPaymentState);
  const loyalty = useAppSelector(selectLoyaltyState);
  const {
    checkCode,
    checkDetails,
    checkDataLoading,
    checkDataError,
    appliedPaymentInfo,
    balanceIsDue,
    isPartiallyPaid,
    isFirstLoad,
    refreshIntervalId,
  } = useCheckData();
  const {
    fpSessionKey,
    cardholderFirstName,
    cardholderLastName,
    billingEmailAddress,
    billingPhoneNumber,
    billingStreetAddress,
    billingCity,
    billingState,
    billingZipCode,
  } = useAppSelector(selectFPAdditionalInput);

  //#endregion Redux Store

  //#region Local States
  const [isPaymentProcess, setIsPaymentProcess] = useState<boolean>(false);
  const [drawerContents, setDrawerContents] = useState<DrawerContentOption>(
    DrawerContentOption.PROCESSING
  );
  const [isIframeLoading, setIsIframeLoading] = useState<boolean>(false);
  const formattedPaymentTotal = useMemo(
    () => currency(paymentTotal).format(),
    [paymentTotal]
  );
  const isSecurePaymentMethod = useMemo(
    () =>
      siteConfig?.PaymentMethod === PaymentMethod.CP ||
      siteConfig?.PaymentMethod === PaymentMethod.FP,
    [siteConfig]
  );
  const isPayButtonDisabled = useMemo(
    () =>
      isPaymentProcess ||
      loyaltyIsProcessing ||
      (paymentTotal < 0.01
        ? false
        : !isSecurePaymentMethod && !isCreditCardInputsValid),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      paymentTotal,
      isPaymentProcess,
      loyaltyIsProcessing,
      siteConfig,
      isCreditCardInputsValid,
    ]
  );
  //#endregion Local States

  //#region Freedom Pay
  const fpApiEndpoint = useMemo(() => siteConfig?.FPApiEndpoint, [siteConfig]);
  const paymentStoreId = siteConfig?.FPStoreId || '';
  const isFreedomPay = useMemo(
    () => siteConfig?.PaymentMethod === PaymentMethod.FP,
    [siteConfig]
  );
  const is3DSFailed = useRef<boolean>(false);
  const is3dsEnabled = useMemo(
    () => isFreedomPay && (siteConfig?.FPEnable3DS || false),
    [isFreedomPay, siteConfig]
  );
  const fpCardholderFirstName = useRef<string>('');
  const fpCardholderLastName = useRef<string>('');
  const fpPaymentStateId = useRef<string>('');
  const fpIframeSessionKey = useRef<string>('');
  const fpBillingZipCode = useRef<string>('');
  const fpEmailAddress = useRef<string>('');
  const fpPhoneNumber = useRef<string>('');
  const fpBillingStreetAddress = useRef<string>('');
  const fpBillingCity = useRef<string>('');
  const fpBillingState = useRef<string>('');

  const googlePaySessionKey = useRef('');
  const googlePayPaymentStateId = useRef('');

  const applePaySessionKey = useRef('');
  const applePayPaymentStateId = useRef('');
  const [isApplePaySupported, setIsApplePaySupported] = useState<boolean>(true);
  //#endregion Freedom Pay

  const tokenRequest: NCRSecurePaymentStartSessionRequest = useMemo(
    () => ({
      ReturnUrl: `${window.location.origin}/callback`,
      CompanyId: siteConfig?.CompanyId,
      CheckId: checkDetails?.Id,
      CheckCode: checkCode,
      Balance: giftCard.isGiftCardAmountPaid
        ? giftCard.balanceRemaining
        : appliedPaymentInfo?.Balance,
      Tip: giftCard.isGiftCardAmountPaid
        ? currency(payment.selectedTip.amount).subtract(giftCard.tipPaid).value
        : payment.selectedTip.amount,
      Gratuity: appliedPaymentInfo?.Gratuity,
      Tax: appliedPaymentInfo?.Tax,
      SiteId: checkDetails?.SiteId,
      PaymentStoreId: paymentStoreId,
      IsSaveNewCard: false,
      LoyaltySavings: loyalty.loyaltyDiscountAmountApplied,
      IsLoyaltyApplied: loyalty.loyaltyIsAssigned,
      TotalAmount: payment.paymentTotal,
      PaymentMethod: PaymentMethod.FP,
      TerminalId: siteConfig?.FPTerminalId || '',
      FPInitType: siteConfig?.FPEnable3DS
        ? FPInitType.ThreeDS
        : FPInitType.Standard,
    }),
    [
      siteConfig?.CompanyId,
      checkDetails?.Id,
      checkDetails?.SiteId,
      checkCode,
      giftCard.isGiftCardAmountPaid,
      giftCard.balanceRemaining,
      giftCard.tipPaid,
      appliedPaymentInfo?.Balance,
      appliedPaymentInfo?.Gratuity,
      appliedPaymentInfo?.Tax,
      payment.selectedTip.amount,
      loyalty.loyaltyDiscountAmountApplied,
      loyalty.loyaltyIsAssigned,
      payment.paymentTotal,
      siteConfig?.FPTerminalId,
      paymentStoreId,
      siteConfig?.FPEnable3DS,
    ]
  );

  //#region Utils
  const displayModal = ({ type, header, text }: ModalOptions) => {
    dispatch(
      setModalOptions({
        type: type,
        header: header,
        text: text,
      })
    );
    dispatch(openModal());
  };

  const handlePaymentError = useCallback(
    (paymentMethod: PaymentMethod, errorMsg?: string) => {
      dispatch(closeDrawer());
      dispatch(resetFPAdditionalInput());
      if (paymentMethod === PaymentMethod.CP) {
        setDrawerContents(DrawerContentOption.CPIFRAME);
      }
      if (paymentMethod === PaymentMethod.FP) {
        setDrawerContents(DrawerContentOption.FPIFRAME);
      }

      dispatch(
        setModalOptions({
          type: 'error',
          header: 'Error',
          text: errorMsg || generalErrorMessages.uhOhMessage,
        })
      );
      dispatch(openModal());
      setIsIframeLoading(false);
      setIsPaymentProcess(false);
      dispatch(setIsFpLoading(false));
      dispatch(setIsFpButtonDisabled(false));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const clearSensitiveData = useCallback(
    (clearGCInputs: boolean) => {
      if (!isSecurePaymentMethod) {
        // clear EDC CC inputs
        dispatch(clearCreditCardState());
      }
      if (clearGCInputs) {
        // Only clear GC inputs when
        // 1. GC payment fails
        // 2. Portal (EDC/CP) payment succeeds
        dispatch(clearGiftCardInputs());
      }
    },
    [dispatch, isSecurePaymentMethod]
  );

  // Return iframe content
  const showIframeContent = useCallback(
    (content: DrawerContentOption) => {
      switch (content) {
        case DrawerContentOption.CPIFRAME:
          return (
            <NCRSecurePaymentIframe
              setIsPaymentProcess={setIsPaymentProcess}
              clearSensitiveData={clearSensitiveData}
              setDrawerContents={setDrawerContents}
              setIsIframeLoading={setIsIframeLoading}
            />
          );
        case DrawerContentOption.FPIFRAME:
          return (
            <FreedomPayIframe
              tokenRequest={tokenRequest}
              isDrawerOpen={open}
              setIsIframeLoading={setIsIframeLoading}
              handlePaymentError={handlePaymentError}
              is3dsEnabled={is3dsEnabled}
              isGooglePayEnabled={isGooglePayEnabled}
              isApplePayEnabled={isApplePayEnabled}
              isApplePaySupported={isApplePaySupported}
              updateGooglePayRef={updateGPayRefs}
              updateApplePayRef={updateAPayRefs}
              processGooglePayPaymentToken={processGooglePayPaymentToken}
            />
          );
        case DrawerContentOption.PROCESSING:
          return (
            <SecurePaymentProcessing message="Please wait while we apply your payment..." />
          );
        default:
          dispatch(closeDrawer());
          dispatch(
            setModalOptions({
              type: 'error',
              header: 'Error',
              text: generalErrorMessages.uhOhMessage,
            })
          );
          dispatch(openModal());
          return (
            <SecurePaymentProcessing
              message={generalErrorMessages.uhOhMessage}
            />
          );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      tokenRequest,
      isApplePaySupported,
      clearSensitiveData,
      open,
      is3dsEnabled,
      isGooglePayEnabled,
      isApplePayEnabled,
    ]
  );

  const isFPEventOriginValid = useCallback(
    (event: MessageEvent<any>): boolean => {
      const paymentType = event.data?.data?.paymentType;

      if (paymentType === FPPaymentType.GooglePay) {
        return (
          event.origin !== fpApiEndpoint && event.origin?.indexOf('10.10') < 0
        );
      } else if (paymentType === FPPaymentType.ApplePay) {
        return event.origin !== window.location.origin;
      } else if (paymentType === FPPaymentType.Card) {
        return (
          event.origin !== fpApiEndpoint && event.origin.indexOf('10.10') < 0
        );
      }

      return false;
    },
    [fpApiEndpoint]
  );

  const generateFPPaymentRequest = useCallback(
    (
      paymentType: string,
      data: any
    ): NCRSecurePaymentMakeTransactionRequest | undefined => {
      var request: NCRSecurePaymentMakeTransactionRequest = {
        sessionId: '',
        sessionKey: '',
        paymentStateId: '',
        checkAssignmentId: checkDetails?.Id || 0,
        siteId: `${checkDetails?.SiteId || 0}`,
        companyId: `${siteConfig?.CompanyId || 0}`,
        paymentKey: '',
        methodType: '',
        maskedCardNumber: '',
        expMonth: '',
        expYear: '',
        zipCode: '',
        paymentMethod: PaymentMethod.FP,
        cardholderFirstName: '',
        cardholderLastName: '',
        billingInformation: {
          FirstName: fpCardholderFirstName.current || '',
          LastName: fpCardholderLastName.current || '',
          EmailAddress: fpEmailAddress.current || '',
          PhoneNumber: fpPhoneNumber.current || '',
          StreetAddress: fpBillingStreetAddress.current || '',
          City: fpBillingCity.current || '',
          State: fpBillingState.current || '',
          ZipCode: fpBillingZipCode.current || '',
        },
      };

      try {
        //#region GooglePay OR ApplePay
        if (
          paymentType === FPPaymentType.ApplePay ||
          paymentType === FPPaymentType.GooglePay
        ) {
          const paymentKeyData: GoogleApplePaymentKeyData = {
            attributes: data.attributes.reduce(
              (prev: any, value: any) => ({
                ...prev,
                [value.Key]: value.Value,
              }),
              {}
            ),
            paymentKeys: data.paymentKeys,
            paymentType: data.paymentType,
          };

          const keyData: MakeSecurePaymentRequest = {
            googleApplePaymentKeyData: paymentKeyData,
            paymentMethod: siteConfig?.PaymentMethod,
            paymentType: data.paymentType,
          };

          // GooglePay
          if (keyData.paymentType === FPPaymentType.GooglePay) {
            request.fpPaymentType = FPInitType.GooglePay;
            request.sessionKey = googlePaySessionKey.current;
            request.paymentStateId = googlePayPaymentStateId.current;
            request.paymentKey =
              keyData.googleApplePaymentKeyData?.paymentKeys[0] || '';
            request.methodType = data.attributes[0].CardIssuer;
            request.maskedCardNumber = data.attributes[0].MaskedCardNumber;
            request.zipCode =
              keyData.googleApplePaymentKeyData?.attributes.postalCode;
            
            const splitName = data.cardholderName.split(' ');
            request.cardholderFirstName = splitName[0] || '';
            request.cardholderLastName = splitName[1] || '';
          }

          if (keyData.paymentType === FPPaymentType.ApplePay) {
            request.fpPaymentType = FPInitType.ApplePay;
            request.sessionKey = applePaySessionKey.current;
            request.paymentStateId = applePayPaymentStateId.current;
            request.paymentKey =
              keyData.googleApplePaymentKeyData?.paymentKeys[0] || '';
            request.methodType = paymentKeyData.attributes.cardIssuer;
            request.maskedCardNumber = maskGoogleApplePayCardNumber(
              keyData,
              FPInitType.ApplePay
            );

            const applePayBillingContact = JSON.parse(
              keyData.googleApplePaymentKeyData?.attributes.billingContact
            );
            request.zipCode = applePayBillingContact.postalCode;
            request.cardholderFirstName = applePayBillingContact.givenName;
            request.cardholderLastName = applePayBillingContact.familyName;
          }

          return request;
        }
        //#endregion GooglePay OR ApplePay

        //#region Normal FP Payment
        if (paymentType === FPPaymentType.Card) {
          const paymentKeyData: FreedomPaymentKeyData = {
            attributes: data.attributes,
            paymentKeys: data.paymentKeys,
            paymentType: data.paymentType,
          };
          const keyData: MakeSecurePaymentRequest = {
            freedomPaymentKeyData: paymentKeyData,
            paymentMethod: siteConfig?.PaymentMethod,
          };
          const fpCcExpDate = splitExpirationDate(
            keyData.freedomPaymentKeyData?.attributes[2].Value || ''
          );
          request.sessionKey = fpIframeSessionKey.current || '';
          request.paymentStateId = fpPaymentStateId.current || '';
          request.checkAssignmentId = checkDetails?.Id || 0;
          request.siteId = `${checkDetails?.SiteId || 0}`;
          request.companyId = `${siteConfig?.CompanyId || 0}`;
          request.paymentKey =
            keyData.freedomPaymentKeyData?.paymentKeys[0] || '';
          request.methodType =
            keyData.freedomPaymentKeyData?.attributes[0].Value || '';
          request.maskedCardNumber =
            keyData.freedomPaymentKeyData?.attributes[1].Value || '';
          request.expMonth = fpCcExpDate[0] || '';
          request.expYear = fpCcExpDate[1] || '';
          request.zipCode =
            keyData.freedomPaymentKeyData?.attributes[3]?.Value || '';
          request.paymentMethod = PaymentMethod.FP;
          request.cardholderFirstName = fpCardholderFirstName.current || '';
          request.cardholderLastName = fpCardholderLastName.current || '';

          if (siteConfig?.FPEnable3DS) {
            request.zipCode = fpBillingZipCode.current;
            request.fpPaymentType = FPInitType.ThreeDS;
          } else {
            request.zipCode =
              keyData.freedomPaymentKeyData?.attributes[3]?.Value || '';
            request.fpPaymentType = FPInitType.Standard;
          }

          return request;
        }
        //#endregion Normal FP Payment
      } catch (error) {
        console.log('Failed to generate payment request');
        throw new Error();
      }

      return undefined;
    },
    [
      checkDetails?.Id,
      checkDetails?.SiteId,
      siteConfig?.CompanyId,
      siteConfig?.FPEnable3DS,
      siteConfig?.PaymentMethod,
    ]
  );

  const updateGPayRefs = (sessionKey: string, paymentStateId: string) => {
    googlePaySessionKey.current = sessionKey;
    googlePayPaymentStateId.current = paymentStateId;
  };

  const updateAPayRefs = (sessionKey: string, paymentStateId: string) => {
    applePaySessionKey.current = sessionKey;
    applePayPaymentStateId.current = paymentStateId;
  };
  //#endregion Utils

  //#region FreedomPay-Google/ApplePay
  const makeFPPayment = useCallback(
    async (keyData: any, paymentType: string): Promise<MpResult> => {
      if (
        paymentType !== FPPaymentType.GooglePay &&
        paymentType !== FPPaymentType.ApplePay &&
        paymentType !== FPPaymentType.Card
      ) {
        return Promise.resolve({
          success: false,
          message: paymentErrorMessages.tryAgainButTalkToServerIfPersist,
        });
      }

      if (
        paymentType === FPPaymentType.ApplePay ||
        paymentType === FPPaymentType.GooglePay
      ) {
        dispatch(setIsFpButtonDisabled(true));
      }

      try {
        const completeSessionRequest:
          | NCRSecurePaymentMakeTransactionRequest
          | undefined = generateFPPaymentRequest(paymentType, keyData);
        if (completeSessionRequest === undefined) {
          console.log('Payment request is undefined');
          throw new Error();
        }

        traceAIEvent(
          {
            message: `[makeFPPayment] completeSessionRequest: ${JSON.stringify(
              completeSessionRequest
            )}`,
          },
          { paymentType: paymentType, CheckCode: checkCode }
        );

        const response = await completeNCRSecurePaymentsTransaction(
          completeSessionRequest
        );

        if (response.data.Status === 216 || response.data.Status === 217) {
          //got soft decline error
          //call FP api to process 3DS
          window.FreedomPay.Apm.ConsumerAuth.invoke();

          return Promise.resolve({
            success: false,
            message: response.data.Message,
            errorCode: response.data.Status,
          });
        } else if (response.data.Status > 0) {
          // This should check if there is a balance due and update all the UI accordingly
          const { data } = await getAppliedPaymentInfo(checkCode);

          if (data === null) {
            // If failed to refresh paymentInfo, send user back to check page with an error modal
            navigateToCheckPage(history);
            return {
              success: false,
              message: paymentErrorMessages.refreshPaymentInfoFailure,
            };
          } else {
            if (
              data.Status === -600 &&
              response.data.PaymentId !== undefined &&
              response.data.PaymentId <= 0
            ) {
              return {
                success: false,
                message: response.data.Message,
              };
            }
            dispatch(setPaymentInfo(data));
            dispatch(setPaidAmount(data.AmountPaid));

            if (data.Balance > 0) {
              dispatch(loadCheckInfo(checkCode));
              dispatch(loadPaymentInfo(checkCode));
              // Do not display error modal if balance is remaining
              return {
                success: false,
                message: response.data.Message,
              };
            }

            dispatch(setPaymentId(response.data.PaymentId));
            return Promise.resolve({
              success: true,
              message: response.data.Message,
            });
          }
        }
        return Promise.resolve({
          success: false,
          message: response.data.Message,
        });
      } catch (error: any) {
        traceAIEvent(
          {
            message: `[makeFPPayment] error: ${JSON.stringify(error)}`,
          },
          { paymentType: paymentType, CheckCode: checkCode }
        );

        return Promise.resolve({
          success: false,
          message: error?.message || 'An error occurred',
        });
      }
    },
    [checkCode, dispatch, generateFPPaymentRequest, history]
  );

  //This is used for only google pay direct integration.
  //we got a payment token from googel pay so, need to send it to FP to get payment key
  //we will process a payment with the payment key.
  const processGooglePayPaymentToken = useCallback(
    async (
      token: string,
      cardIssuer: string,
      maskedCardNumber: string,
      cardholderName: string
    ): Promise<MpResult> => {
      if (token === null || token === undefined) {
        return Promise.resolve({
          success: false,
          message: paymentErrorMessages.tryAgainButTalkToServerIfPersist,
        });
      }

      try {
        dispatch(setIsFpButtonDisabled(true));

        const keyRequest: FreedomPayKeyRequest = {
          cardData: token,
          tokenPaymentType: 5,
          sessionKey: googlePaySessionKey.current,
          paymentStateId: googlePayPaymentStateId.current,
        };

        if (keyRequest === undefined) {
          console.log('key request is undefined');
          throw new Error();
        }

        //now we send GPay payment token to FP to get FP payment key
        const response = await sendPaymentKey(keyRequest);

        if (response.data === null || response.data.paymentKeys.length === 0) {
          return Promise.resolve({
            success: false,
            message: paymentErrorMessages.tryAgainButTalkToServerIfPersist,
            errorCode: response.status,
          });
        }

        const paymentType = response.data.paymentType;

        const data = {
          paymentKeys: response.data.paymentKeys,
          paymentType: paymentType,
          attributes: [
            { CardIssuer: cardIssuer, MaskedCardNumber: maskedCardNumber },
          ],
          cardholderName: cardholderName,
        };

        var gotSoftDecline = false;

        try {
          const result = await makeFPPayment(data, paymentType);

          if (result.success) {
            clearSensitiveData(true);
            navigateToPaymentConfirmationPage(history);
          } else {
            gotSoftDecline =
              result.errorCode === 216 || result.errorCode === 217;

            if (!gotSoftDecline) {
              handlePaymentError(
                PaymentMethod.FP,
                result.message || paymentErrorMessages.processingFailure
              );
            } else {
              //we got soft decline error code (216/217)
              //just call FP api in makeFPPayment() so we need to stay here
              //and wait another respose.
              //iframe needs to be here. don't close drawer for now.
            }
          }
        } catch (error) {
          handlePaymentError(PaymentMethod.FP);
        } finally {
          if (!gotSoftDecline) {
            setIsPaymentProcess(false);
            setIsIframeLoading(false);
            dispatch(setIsFpLoading(false));
            dispatch(resetFPAdditionalInput());
            dispatch(closeDrawer());
          }
        }

        return Promise.resolve({
          success: true,
          message: 'success',
        });
      } catch (error: any) {
        return Promise.resolve({
          success: false,
          message: error?.message || 'An error occurred',
        });
      } finally {
        dispatch(setIsFpButtonDisabled(false));
      }
    },
    [clearSensitiveData, dispatch, handlePaymentError, history, makeFPPayment]
  );

  const freedomPayEventListener = useCallback(
    async (event: MessageEvent<any>) => {
      if (isFPEventOriginValid(event)) {
        handlePaymentError(PaymentMethod.FP);
        return;
      }

      const message = event.data;
      const data = message.data;

      switch (message.type) {
        case FreedomPayMessageType.ERROR:
          if (data && data.errors && data.errors.length > 0) {
            data.errors.forEach((error: any) => {
              if (
                error.emittedBy === 'ApplePay' &&
                error.messageCode === 'applePayBrowserNotSupported'
              ) {
                setIsApplePaySupported(false);
              } else if (
                error.emittedBy === '3d secure' &&
                error.messageCode === 'consumerAuthCanceled'
              ) {
                handlePaymentError(PaymentMethod.FP);
                return;
              }
            });
            console.log(data.errors);
          }
          break;
        case FreedomPayMessageType.SET_HEIGHT:
          dispatch(setFpIframeHeight(data.height));
          break;
        case FreedomPayMessageType.SUBMIT:
          if (is3DSFailed.current) {
            is3DSFailed.current = false;
            handlePaymentError(PaymentMethod.FP);
            break;
          }

          setIsPaymentProcess(true);

          //If we receive an error code, 216 or 217, for FP payment, we will
          //process 3DS step up challenge and
          //get FreedomPayMessageType.SUBMIT event again.
          //So, don't want to close drawer if we have 216 or 217
          var gotSoftDecline = false;

          try {
            if (
              data &&
              data.attributes &&
              data.paymentKeys &&
              data.paymentType
            ) {
              const paymentType = data.paymentType;
              const result = await makeFPPayment(data, paymentType);

              if (result.success) {
                clearSensitiveData(true);
                navigateToPaymentConfirmationPage(history);
              } else {
                gotSoftDecline =
                  result.errorCode === 216 || result.errorCode === 217;

                if (!gotSoftDecline) {
                  handlePaymentError(
                    PaymentMethod.FP,
                    result.message || paymentErrorMessages.processingFailure
                  );
                } else {
                  //we got soft decline error code (216/217)
                  //just call FP api in makeFPPayment() so we need to stay here
                  //and wait another respose.
                  //iframe needs to be here. don't close drawer for now.
                }
              }
            }
          } catch (error) {
            handlePaymentError(PaymentMethod.FP);
          } finally {
            if (!gotSoftDecline) {
              setIsPaymentProcess(false);
              setIsIframeLoading(false);
              dispatch(setIsFpLoading(false));
              dispatch(setIsFpButtonDisabled(false));
              dispatch(resetFPAdditionalInput());
              dispatch(closeDrawer());
            }
          }
          break;
        case FreedomPayMessageType.THREEDS_HANDLE:
          if (is3DSAuthFailed(data)) {
            console.log('3DS auth is failed. data:', data);
            is3DSFailed.current = true;
          }
          break;
      }
      return;
    },
    [
      clearSensitiveData,
      dispatch,
      handlePaymentError,
      history,
      makeFPPayment,
      isFPEventOriginValid,
    ]
  );
  //#endregion FreedomPay-Google/ApplePay

  //#region useEffect
  // Polling Check Data on Payment Page
  useEffect(
    () => {
      dispatch(setCurrentPage(PageName.Payment));

      const interval = setInterval(async () => {
        const checkCode = cookies.get(codeCookie);
        const isFirstLoad = cookies.get(firstLoadCookie) === 'true';
        const { data: checkDetails, headers } = await getCheckInfo(
          checkCode,
          isFirstLoad
        );
        const { data: appliedPaymentInfo } = await getAppliedPaymentInfo(
          checkCode
        );

        const isETagSame = headers.etag === cookies.get(eTagCookie);

        if (!isFirstLoad && !isETagSame) {
          cookies.set(eTagCookie, headers.etag, {
            path: '/',
            maxAge: 3600 * 24,
          });

          dispatch(
            setCheckInfo({
              checkCode: checkCode,
              checkDetails: checkDetails,
              checkDetailsStatus: 'idle',
              checkDetailsErrorResponse: null,
              failedCheckLoadAttempts: 0,
              appliedPaymentInfo: appliedPaymentInfo,
              paymentInfoStatus: 'idle',
              paymentInfoErrorResponse: null,
              failedPaymentInfoLoadAttempts: 0,
              isFirstLoad: isFirstLoad,
              eTag: headers.etag,
              refreshIntervalId: refreshIntervalId,
            })
          );
        }
      }, checkDataPollingIntervalMs);

      dispatch(setRefreshIntervalId(interval));

      return () => {
        clearInterval(interval);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // If a check is closed and no balance, send user back to check page
  useEffect(() => {
    if (
      (!balanceIsDue &&
        checkDetails?.LoyaltyName != null &&
        appliedPaymentInfo?.Closed === true) ||
      (!balanceIsDue && checkDetails?.LoyaltyName == null)
    ) {
      navigateToCheckPage(history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Check balance after payment info is loaded when payment page is reloaded through refresh or URL change
    // On first load, isFirstLoad becomes true => false, which indicates balance is ready to be verified
    if (
      (!balanceIsDue &&
        checkDetails?.LoyaltyName != null &&
        appliedPaymentInfo?.Closed === true) ||
      (!balanceIsDue && checkDetails?.LoyaltyName == null)
    ) {
      navigateToCheckPage(history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFirstLoad]);

  //TODO handle error more gracefully
  useEffect(() => {
    if (checkDataError) {
      dispatch(
        setModalOptions({
          type: 'error',
          header: 'Error',
          text: generalErrorMessages.uhOhMessage,
        })
      );
      dispatch(openModal());
      navigateToCheckPage(history);
    } else {
      if (!isPaymentProcess && !loyaltyIsProcessing) {
        dispatch(updatePaymentTotal());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appliedPaymentInfo?.Balance,
    checkDataError,
    checkDetails,
    dispatch,
    history,
  ]);

  //TODO: how can we make this more universal? history doesn't seem to update in a lot of places.
  useEffect(() => {
    return history.listen(() => {
      if (
        history?.action &&
        (history.action === 'POP' || history.action === 'PUSH')
      ) {
        dispatch(closeModal());
      }
    });
  }, [dispatch, history]);

  // Update DrawerContent
  useEffect(() => {
    if (siteConfig?.PaymentMethod === PaymentMethod.CP) {
      removeFreedomPayScript();
      setDrawerContents(DrawerContentOption.CPIFRAME);
      return;
    }

    if (siteConfig?.PaymentMethod === PaymentMethod.FP) {
      addFreedomPayScript(siteConfig?.FPApiEndpoint);
      setDrawerContents(DrawerContentOption.FPIFRAME);
      window.addEventListener('message', freedomPayEventListener);

      return () =>
        window.removeEventListener('message', freedomPayEventListener);
    }

    setDrawerContents(DrawerContentOption.PROCESSING);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteConfig?.FPEnableGooglePay, siteConfig?.PaymentMethod]);

  useEffect(() => {
    fpCardholderFirstName.current = cardholderFirstName || '';
  }, [cardholderFirstName]);
  useEffect(() => {
    fpCardholderLastName.current = cardholderLastName || '';
  }, [cardholderLastName]);
  useEffect(() => {
    fpPaymentStateId.current = payment.paymentStateId || '';
  }, [payment.paymentStateId]);
  useEffect(() => {
    fpIframeSessionKey.current = fpSessionKey || '';
  }, [fpSessionKey]);
  useEffect(() => {
    fpBillingZipCode.current = billingZipCode || '';
  }, [billingZipCode]);
  useEffect(() => {
    fpEmailAddress.current = billingEmailAddress || '';
  }, [billingEmailAddress]);
  useEffect(() => {
    fpPhoneNumber.current = billingPhoneNumber || '';
  }, [billingPhoneNumber]);
  useEffect(() => {
    fpBillingStreetAddress.current = billingStreetAddress || '';
  }, [billingStreetAddress]);
  useEffect(() => {
    fpBillingCity.current = billingCity || '';
  }, [billingCity]);
  useEffect(() => {
    fpBillingState.current = billingState || '';
  }, [billingState]);
  //#endregion useEffect

  return (
    <>
      <MpHeader />
      <ContentContainer>
        {isPartiallyPaid && !isPaymentProcess ? <PartiallyPaid /> : null}
        <CheckCodeHeader />
        <CheckSummary
          totals={checkDetails?.Totals}
          payments={checkDetails?.Payments}
          tenders={checkDetails?.Tenders}
          preLoyaltyItemsTotal={checkDetails?.PreDiscountItemsTotal || 0}
          serviceCharges={checkDetails?.ServiceCharges}
          loading={
            checkDataLoading && !isPaymentProcess && !loyaltyIsProcessing
          }
          isGiftCardAmountPaid={giftCard.isGiftCardAmountPaid}
        />
        {siteConfigStatus === 'loading' &&
        !isPaymentProcess &&
        !loyaltyIsProcessing ? (
          <PaymentSectionLoading />
        ) : (
          <>
            {siteConfig?.IsUseTipConfiguration ? (
              <TipSection loading={isPaymentProcess || loyaltyIsProcessing} />
            ) : null}
            {siteConfig?.AlohaLoyalty ? (
              <AddLoyaltySection
                disabled={
                  checkDataLoading || isPaymentProcess || loyaltyIsProcessing
                }
              />
            ) : null}
            {siteConfig?.StoredValueEnabled && !isLoyaltyASVCard ? (
              <GiftCardSection
                disabled={
                  checkDataLoading || isPaymentProcess || loyaltyIsProcessing
                }
              />
            ) : null}
            {isSecurePaymentMethod ? null : (
              <CreditCardInputSection
                loading={isPaymentProcess || loyaltyIsProcessing}
                disabled={paymentTotal < 0.01}
              />
            )}
            <PayButton
              checkCode={checkCode}
              checkDetails={checkDetails}
              appliedPaymentInfo={appliedPaymentInfo}
              siteConfig={siteConfig}
              disabled={isPayButtonDisabled}
              loading={isPaymentProcess}
              setIsPaymentProcess={setIsPaymentProcess}
              setIsIframeLoading={setIsIframeLoading}
              clearSensitiveData={clearSensitiveData}
              displayModal={displayModal}
            />
          </>
        )}

        <Drawer
          isOpen={open}
          isLoading={isIframeLoading}
          title={`Pay ${formattedPaymentTotal} to ${
            siteConfig?.ExternalStoreName || siteConfig?.StoreName
          }`}
          width="100%"
          from="bottom"
          onRequestClose={() => {
            dispatch(closeDrawer());
            dispatch(resetFPAdditionalInput());
          }}
        >
          {showIframeContent(drawerContents)}
        </Drawer>
      </ContentContainer>
    </>
  );
};
